<template>
  <div class="currency-picker d-flex align-center clickable">
    <span
      class="selected-currency"
      @click="toggleEdit"
      v-if="!showCurrencyPicker"
    >
      <document-name-ref
        :id="selected.id"
        :cache-type="currencyCacheType"
      />
    </span>
    <div
      v-else
      class="wrapper"
      ref="wrapper"
    >
      <v-autocomplete
        class="currencies"
        hide-no-data
        hide-details
        return-object
        no-filter
        :attach="typeof attach !== 'undefined' ? attach : $refs.wrapper"
        :dense="dense"
        :solo="solo"
        :flat="flat"
        @update:search-input="setSearchText($event)"
        :clearable="clearable"
        :items="suggestions"
        item-text="name"
        item-value="id"
        :label="label"
        :rules="rules"
        :disabled="disabled"
        :readonly="readonly"
        :loading="computedLoading"
        :no-data-text="$t(computedLoading ? 't.Loading' : 't.NoResult')"
        :rounded="rounded"
        :placeholder="placeholder"
        :menu-props="computedMenuProps"
        v-model="value"
        v-click-outside="clickOutside"
        @change="propagateChange"
        @focus="handleFocus"
        @keydown.esc="closeMenu"
        @mouseup="handleClick"
        item-color=""
        ref="autocomplete"
      >
        <template v-slot:append>
          <slot name="append" />
        </template>

        <template v-slot:item="data">
          <v-list-item-content>
            <document-picker-list-item-ref
              :item="data.item"
              :show-icon="false"
              class="clickable"
            />
          </v-list-item-content>
        </template>
        <template
          v-if="search.resultCount > search.items.length"
          v-slot:append-item
        >
          <v-list-item
            @mousedown="search.retrieveNextPage()"
            class="clickable"
            ref="more"
          >
            <v-list-item-content>
              <document-picker-list-item-fallback :item="{name: $tc('t.MoreItems', search.resultCount - search.items.length), type: 'labels'}" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import { CacheType } from '@/wasm/pkg'
import Search from '@/pages/search/controllers'

export default {
  components: {
    DocumentPickerListItemRef: () => import('@/components/document-picker-list-item-ref'),
    DocumentPickerListItemFallback: () => import('@/components/document-picker-list-item-fallback'),
    DocumentNameRef: () => import('@/components/documents-ref/document-name-ref')
  },
  data: function () {
    return {
      dataCurrency: null,
      showCurrencyPicker: false,
      currencyCacheType: CacheType.RelativeCurrencyRef,
      currentPage: 0,
      http: this.$http().debounced(),
      innerLoading: false,
      isOutOfView: false,
      menuIsOpen: false,
      observer: new ResizeObserver(this.eventHandler),
      innerSelectedDocument: null,
      innerSelectedIds: [],
      innerValue: [],
      listItemHeight: 1,
      updatingSelectedValues: false,
      search: new Search()
        .chain(s => s.searchedDocumentTypes.include(Array.isArray(this.documentTypes) ? this.documentTypes : [this.documentTypes]))
    }
  },
  computed: {
    clickOutside () {
      return {
        handler: this.closeMenu,
        include: () => {
          const el = this.$refs?.more?.$el
          return el ? [el] : []
        }
      }
    },
    computedLoading () {
      return this.search.loading || (this.observer.status === 1)
    },
    computedMenuProps: {
      get () {
        const top = typeof this.top === 'boolean' ? this.top : this.isOutOfView
        return {
          closeOnContentClick: false,
          contentClass: 'background-plain',
          eager: this.attach !== false,
          maxHeight: 5 * this.listItemHeight + 16,
          nudgeBottom: top ? 0 : 8,
          offsetY: true,
          top,
          value: this.menuIsOpen
        }
      }
    },
    suggestions () {
      return this.search.items
    },
    value: {
      get () {
        return this.dataCurrency
      },
      set (v) {
        this.dataCurrency = v
        this.emitDocument()
      }
    }
  },
  methods: {
    setSearchText (v) {
      if (v) {
        this.search.searchText = v
      }
    },
    toggleEdit () {
      if (this.readonly) {
        this.showCurrencyPicker = false
      } else {
        this.showCurrencyPicker = !this.showCurrencyPicker
        if (this.showCurrencyPicker) {
          this.handleClick()
          this.handleFocus()
          this.$nextTick(() => this.$refs.autocomplete.focus())
        }
      }
    },
    emitDocument () {
      const doc = this.lodash.cloneDeep(this.dataCurrency)
      this.$emit('update:selected', doc)
    },
    closeMenu () {
      this.menuIsOpen = false
      this.showCurrencyPicker = false
    },
    openMenu () {
      this.$nextTick(() => {
        this.menuIsOpen = true
        this.$refs.autocomplete?.activateMenu()
      })
    },
    handleClick () {
      if (this.readonly) {
        return
      }
      this.openMenu()
      this.isAppendClick = false
      this.$nextTick(() => this.computeDimensions())
    },
    handleFocus () {
      this.dataCurrency = null
      this.search.clearText().execute()
    },
    async computeDimensions () {
      this.listItemHeight = await this.$waitFor(() => document.querySelector(`#${this.$refs.autocomplete.computedOwns} .v-list-item:first-child`).clientHeight)
    },
    eventHandler (entries) {
      if (!this.$refs.wrapper) { return } // Wrapper can be detached when the picker is hidden, the boundaries should not be checked in this case
      const rect = entries[entries.length - 1].contentRect
      this.isOutOfView = (this.$refs.wrapper.getBoundingClientRect().bottom + rect.bottom) > document.querySelector(this.bottomElementSelector).getBoundingClientRect().top
      this.$triggerResize()
    },
    propagateChange (e) {
      this.showCurrencyPicker = false

      this.currencyCacheType = e?.id?.length !== 3 ? CacheType.RelativeCurrencyRef : CacheType.CurrencyRef

      this.$emit('change', e)
    }
  },
  async mounted () {
    const e = await this.$waitFor(() => this.$el.querySelector('div.v-menu__content'))
    if (e) {
      this.observer.observe(e)
    }
  },
  beforeDestroy () {
    this.search = undefined
    this.observer.disconnect()
  },
  watch: {
    computedLoading (n) {
      this.$emit('update:loading', n)
    },
    selected: {
      immediate: true,
      handler (n) {
        this.updatingSelectedValues = true
        this.dataCurrency = n
        if (n) {
          this.currencyCacheType = n?.id?.length !== 3 ? CacheType.RelativeCurrencyRef : CacheType.CurrencyRef
        }
      }
    }
  },
  props: {
    bottomElementSelector: {
      type: String,
      default: 'footer'
    },
    flat: Boolean,
    solo: Boolean,
    label: String,
    documentTypes: {
      required: true,
      type: [Array, String]
    },
    excludedItems: {
      type: Array,
      required: false
    },
    dense: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    rules: Array,
    selected: Object,
    disabled: Boolean,
    readonly: Boolean,
    loading: Boolean,
    placeholder: {
      type: String,
      default () { return this.$t('t.Currency') }
    },
    result: Object,
    rounded: Boolean,
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    top: {
      type: Boolean,
      default: undefined
    },
    navigate: Boolean,
    linkTarget: Object,
    chip: Boolean,
    readonlyList: Boolean
  }
}
</script>

<style lang="stylus" scoped>
.wrapper
  position relative

.currencies
  width 120px

.selected-currency
  font-size 0.8em
</style>
